import React, { useContext, useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import axios from 'axios';
import showdown from "showdown"
import IconSelect from '../icons/IconSelect';

import SiteContext from '../SiteContext';
import CompanyEmailFormStep1 from './CompanyEmailFormStep1';
import CompanyEmailFormStep2 from './CompanyEmailFormStep2';

export default function CompanyEmailForm({ setQuery, formIntro, selectedCompany, setBannerState }) {
  const [formData, setFormData] = useContext(SiteContext);
  const [postingData, setPostingData] = useState(false);
  const [step, setStep] = useState(1);

  // = = = = = = = = 
  // Forestry Query
  const data = useStaticQuery(graphql`
    query RegistrationForm {
      interestForm: allMarkdownRemark(filter: {frontmatter: {form_title: {eq: "Express Interest Form"}}}) {
        nodes {
          frontmatter {
            form_consent
          }
        }
      }
    }
  `)

  // Convert Markdown to HTML
  const converter = new showdown.Converter();
  const htmlFormConsent = data.interestForm.nodes[0].frontmatter.form_consent;
  
  // = = = = = = = = 
  // Events Functions

  // Add data to States
  const handleChange = e => {
  
    // Set Form Data
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Add data from selectedCompany if these fields are undefined
    if(formData.company === undefined) {
      setFormData({
        ...formData,
        company: selectedCompany.name,
        [e.target.name]: e.target.value
      });
    }
    if(formData.address === undefined) {
      setFormData({
        ...formData,
        address: selectedCompany.addressShort,
        [e.target.name]: e.target.value
      });
    }
    if(formData.postcode === undefined) {
      setFormData({
        ...formData,
        postcode: selectedCompany.postCode,
        [e.target.name]: e.target.value
      });
    }
    // Check this data by default
    if(formData.workingSince === undefined) {
      setFormData({
        ...formData,
        workingSince: 'I have worked continuously for this organisation in the City since September last year.',
        [e.target.name]: e.target.value
      });
    }
    // Add consent if user didn't interact with it's radio buttons
    if(formData.consent1 === undefined) {
      setFormData(prevFormData => ({
        ...prevFormData,
        consent1: 'no'
      }));

    }

    if(formData.consent2 === undefined) {
      setFormData(prevFormData => ({
        ...prevFormData,
        consent2: 'no'
      }));
    }
  }



  // Submits Form Data
  const handleSubmit = e => {
    e.preventDefault();
    let myForm = e.target;

    // Show spinner
    setPostingData(true);

    // console.log('formData', formData);

    // Send to Netlify Forms
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": myForm.getAttribute('name'),
        ...formData
      }).toString()
    })
    .then(async (response) => {
      if (!response.ok) throw Error(response.statusText);
      // Send to Serverless Function
      axios.post('/.netlify/functions/getCompanyDataFinal', {
        companyId: selectedCompany.id,
        companyName: selectedCompany.name,
        userName: `${formData.firstName} ${formData.lastName}`,
        userEmail: formData.email,
        userPhone: formData.phone,
        contactName: formData.contactName,
        contactEmail: formData.contactEmail,
        contactJobTitle: formData.contactJobTitle,
        nationality: formData.nationality,
        workingSince: formData.workingSince,
      })
    })
    .then(response => {
      // Hide spinner
      setPostingData(false);
      // Go to final page
      setBannerState('final');
    })
    .catch((error) => {
      setPostingData(false);
      alert(error);
    })
  }

  return (
    <form
      onSubmit={ (e) => handleSubmit(e) } 
      className="form form--interest"  
      name="Company Email Form" 
      method="POST" 
      netlify-honeypot="bot-field"
      data-netlify="true"
      id="company-email-form"
    >
      <input readOnly type="hidden" name="form-name" value="Company Email Form" />
      {
        step === 1
          &&
        <CompanyEmailFormStep1
          formIntro={formIntro}
          formData={formData} 
          selectedCompany={selectedCompany} 
          handleChange={handleChange}
          setStep={setStep}
          postingData={postingData}
          step={step}
          setBannerState={setBannerState}
          setQuery={setQuery}
        />
      }
      {
        step === 2
         &&
        <CompanyEmailFormStep2
          htmlFormConsent={htmlFormConsent}
          formData={formData} 
          setFormData={setFormData}
          handleChange={handleChange}
          setStep={setStep}
          postingData={postingData}
          handleSubmit={handleSubmit}
        />
      }
    </form>
  )
}